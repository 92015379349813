import * as React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import ReactAudioPlayer from "react-audio-player"

import LayoutEnglish from "../../components/layoutEnglish"
import Seo from "../../components/seo"

const renderMap = (data) => {
  const position = [data.mdx.frontmatter.lat, data.mdx.frontmatter.lng] 
  if (typeof window !== 'undefined') {
    return (
        <MapContainer id="map" center={position} zoom={17} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        <Marker position={position}>
        </Marker>
      </MapContainer>
    )
  }
  return null
}

const renderImage = (data) => {
  const imageUrl = data.mdx.frontmatter.image_URL
  return <img style={{ float: `right`, maxWidth: `450px`, paddingLeft: `24px` }} src={imageUrl} alt={data.mdx.frontmatter.sub_title} />
}

const StoryPage = ({ data }) => (
  <LayoutEnglish>
    <Seo title={data.mdx.frontmatter.title} />
    <h1>{data.mdx.frontmatter.title}</h1>
    <p>Code: {data.mdx.frontmatter.code}, Lat: {data.mdx.frontmatter.lat}, Lng: {data.mdx.frontmatter.lng}</p>
    {renderMap(data)}
    <ReactAudioPlayer
      src={data.mdx.frontmatter.sound_URL_web}
      controls
    />
    <div style={{ paddingTop: `48px` }}>
      {renderImage(data)}
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
    </div>
    <Link to="/en/karte">Go back to the homepage</Link>
  </LayoutEnglish>
)

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        sub_title
        lat
        lng
        code
        sound_URL_web
        image_URL
        tags
      }
      body
    }
  }
`

export default StoryPage
